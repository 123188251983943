import {FormDialog, fullDateTime} from '@management-ui/core';
import {Add, Refresh, Visibility} from '@mui/icons-material';
import {IconButton, Tooltip} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import Table from '../../../../components/Table';
import routes from '../../../../routes';
import Status from '../../components/Status';
import ReviewRequestForm from '../../forms/ReviewRequestForm';

const ReviewRequests = ({job, loading, onLoading}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const [requests, setRequests] = useState([]);
  const [showNewForm, setShowNewForm] = useState(false);
  const loaded = useRef(false);

  const loadRequests = useCallback(() => {
    onLoading(true);
    services.reviewRequest.getRequests({filter: {job: job.id}}, 1, 500)
      .then(response => {
        onLoading(false);
        setRequests(response.data);
      }).catch(() => onLoading(false));
  }, [job, onLoading, services]);

  useEffect(() => {
    if (job) {
      if (!loaded.current) {
        loaded.current = true;
        loadRequests();
      }
    }
  }, [job, loadRequests]);

  const goToDetail = useCallback((request) => {
    history.push(reverse(routes.reviewRequests.detail, {id: request.id}));
  }, [history]);

  return (
    <>
      <Table className={loading ? 'loading' : ''}>
        <Table.Actions>
          <h3>Review Requests</h3>
          <Tooltip title="Refresh">
            <IconButton onClick={() => loadRequests()}>
              <Refresh/>
            </IconButton>
          </Tooltip>
          <Tooltip title="Request Review">
            <span>
              <IconButton
                onClick={() => setShowNewForm(true)}
                disabled={job.archived}>
                <Add/>
              </IconButton>
            </span>
          </Tooltip>
        </Table.Actions>
        <Table.Row>
          <Table.Header>Date/Time</Table.Header>
          <Table.Header>Recipient</Table.Header>
          <Table.Header>Products</Table.Header>
          <Table.Header>Providers</Table.Header>
          <Table.Header>Status</Table.Header>
          <Table.Action/>
        </Table.Row>
        {requests.length > 0 ? (
          requests.map((request, index) => (
            <Table.Row key={index}>
              <Table.Column>{request.sent_at ? fullDateTime(request.sent_at) : '-'}</Table.Column>
              <Table.Column>{request.name} ({request.type === 'email' ? request.email : request.phone})</Table.Column>
              <Table.Column>{request.products.map(({name}) => name).join(', ')}</Table.Column>
              <Table.Column>{request.providers.map(({name}) => name).join(', ')}</Table.Column>
              <Table.Column><Status entity={request}/></Table.Column>
              <Table.Action>
                <Tooltip title="View Request">
                  <span>
                    <IconButton
                      onClick={() => goToDetail(request)}
                      disabled={job.archived}>
                      <Visibility/>
                    </IconButton>
                  </span>
                </Tooltip>
              </Table.Action>
            </Table.Row>
          ))
        ) : (
          loading ? null : <Table.NoRecords>There are no review requests associated with this job</Table.NoRecords>
        )}
      </Table>
      <FormDialog
        open={showNewForm}
        title="New Review Request"
        maxWidth="lg"
        render={useCallback((props) => (
          <ReviewRequestForm request={{}} job={job} onSaved={goToDetail} {...props} />
        ), [goToDetail, job])}
        onClose={() => setShowNewForm(false)}/>
    </>
  );
};

export default ReviewRequests;
