import {DataTable, fullDate, name} from '@management-ui/core';
import {MTableToolbar} from '@material-table/core';
import {Approval, Reviews} from '@mui/icons-material';
import CalendarToday from '@mui/icons-material/CalendarToday';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import Currency from '../../../../components/Currency';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import Filter from '../../components/Filter';
import Status from '../../components/Status';

const STATUSES = [
  {
    title: 'Paid',
    slug: '',
    colour: '#30b046'
  },
  {
    title: 'Outstanding',
    slug: 'outstanding',
    colour: '#43ded9'
  },
  {
    title: 'All',
    slug: 'all',
    colour: '#000'
  },
];

const Table = (
  {
    referrer,
    showHeader = true,
    filterStatus = true,
    range = null,
    onDataUpdated = null
  }
) => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const {search, pathname} = useLocation();

  /** @type {({current: DataTable})} */
  const tableRef = useRef();
  const firstLoad = useRef(true);
  const [initialised, setInitialised] = useState(false);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (filterStatus) {
      const params = new URLSearchParams(search);
      const status = params.get('status');
      const current = STATUSES.find(({slug}) => slug === (status ?? ''));
      if (current) {
        setStatus(current);
        setInitialised(true);
        if (!firstLoad.current) {
          tableRef.current.refresh();
        }
        firstLoad.current = false;
      } else {
        history.push(reverse(routes.orders.index));
      }
    } else {
      setInitialised(true);
      if (!firstLoad.current) {
        tableRef.current.refresh();
      }
      firstLoad.current = false;
    }
  }, [history, search, filterStatus, range]);

  const changeStatus = useCallback((selected) => {
    history.push({
      pathname,
      search: selected ? `?status=${selected}` : ''
    });
  }, [history, pathname]);

  const goToDetail = useCallback((order) => {
    history.push(reverse(routes.orders.detail, {id: order.id}));
  }, [history]);

  const loadData = useCallback(query => new Promise((resolve, reject) => {
    const filters = {};
    let orderBy = {field: 'created_at'};
    if (filterStatus) {
      if (status?.slug) {
        if (status.slug !== 'all') {
          filters.status = status.slug;
        }
      } else {
        filters.status = 'paid';
        orderBy = {field: 'completed_at'};
      }
    } else {
      filters.status = 'paid';
      orderBy = {field: 'completed_at'};
    }
    if (referrer) {
      filters.referrer = referrer.id;
    }
    if (range) {
      filters.range = {from: range.from.format('YYYY-MM-DD'), to: range.to.format('YYYY-MM-DD')};
    }
    services.order.getTableOrders({...query, orderBy, orderDirection: 'desc'}, filters)
      .then(response => {
        if (onDataUpdated) {
          onDataUpdated(response.data);
        }
        resolve({
          data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
        });
      }).catch(() => {
      reject();
    });
  }), [referrer, range, filterStatus, onDataUpdated, services, status]);

  const tableProps = useMemo(() => ({
    components: filterStatus ? {
      Toolbar: props => (
        <div>
          <MTableToolbar {...props} />
          <Filter status={status.slug} onChange={changeStatus} statuses={STATUSES}/>
        </div>
      )
    } : {},
    columns: [
      {title: 'Reference', field: 'reference'},
      {
        title: 'Customer',
        field: 'customer',
        render: ({billing_first_name, billing_last_name}) => name({
          first_name: billing_first_name,
          last_name: billing_last_name
        })
      },
      {title: 'Date Started', field: 'created_at', render: data => fullDate(data.created_at)},
      {
        title: 'Date Placed',
        field: 'completed_at',
        render: data => data.status?.slug === 'paid' && data.completed_at ? fullDate(data.completed_at) : '-'
      },
      {title: 'Total', field: 'total', render: data => <Currency amount={data.total}/>},
      {title: 'Status', field: 'status', render: data => <Status entity={data}/>},
    ],
    onRowClick: goToDetail,
    loadData
  }), [filterStatus, status, changeStatus, loadData, goToDetail]);

  return initialised ? (
    <DataTable
      ref={tableRef}
      title={showHeader ? 'Orders' : ''}
      actions={showHeader ? {
        after: [
          {
            tooltip: 'Date Requests',
            icon: CalendarToday,
            isFreeAction: true,
            onClick: () => history.push(routes.dateRequests.index),
          },
          {
            tooltip: 'Review Requests',
            icon: Reviews,
            isFreeAction: true,
            onClick: () => history.push(routes.reviewRequests.index),
          },
          {
            tooltip: 'Referrers',
            icon: Approval,
            isFreeAction: true,
            onClick: () => history.push(routes.referrers.index),
          }
        ]
      } : {}}
      options={{
        search: false,
        headerStyle: {background: 'white', position: 'sticky', top: 0},
        maxBodyHeight: 'calc(100vh - 300px)',
        sorting: false,
        draggable: false
      }}
      {...tableProps}
      newForm={null}
    />
  ) : null;
};

export default Table;
