import {Breadcrumbs} from '@management-ui/core';
import {Paper} from '@mui/material';
import * as React from 'react';
import routes from '../../../../routes';
import Table from './Table';

export default function ReviewRequests() {
  return (
    <div>
      <Breadcrumbs crumbs={[{title: 'Orders', link: routes.orders.index}, {title: 'Review Requests'}]}/>
      <Paper>
        <Table/>
      </Paper>
    </div>
  );
}
