import {
  ConfirmationDialog,
  DetailColumns,
  DetailPane,
  fullDateTime,
  useDetail,
  useDialogs,
  useErrors
} from '@management-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {Box} from '@mui/material';
import {reverse} from 'named-urls';
import * as React from 'react';
import {useCallback, useContext, useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import Status from '../../components/Status';
import Wrapper from './Wrapper';
import SendIcon from '@mui/icons-material/Send';

const ReviewRequest = ({archive = false}) => {
  const services = useContext(ServiceContext);
  const history = useHistory();

  const {errors, toggleError} = useErrors(useMemo(() => ({
    request: 'Sorry the review request could not be accessed'
  }), []));

  const {
    entity: request,
    setEntity: setRequest,
    crumbs,
    loading,
    setLoading
  } = useDetail(
    services.reviewRequest.getRequest,
    archive,
    useCallback((request) => [{title: request.job?.reference ?? request.name}], []),
    useMemo(() => Wrapper.standardCrumbs, []),
    useMemo(() => Wrapper.archiveCrumbs, []),
    useCallback((displayError) => toggleError('request', displayError), [toggleError])
  );

  const {openDialogs, toggleDialog} = useDialogs(['send', 'resend', 'delete']);

  return (
    <Wrapper title="Review Request" loading={loading} request={request} crumbs={crumbs} errors={errors}>
      {request?.id ? (
        <DetailColumns columns={[

          <DetailPane
            title="Request Details"
            minHeight={400}
            actions={[
              ...(!request.sent_at ? [
                {title: 'Send Request', icon: <SendIcon/>, onClick: () => toggleDialog('send', true)}
              ] : [
                {title: 'Resend Request', icon: <SendIcon/>, onClick: () => toggleDialog('resend', true)}
              ]),
              ...(!request.archived ? [
                {title: 'Delete Request', icon: <DeleteIcon/>, onClick: () => toggleDialog('delete', true)}
              ] : request.deleted_at ? [
                {
                  title: 'Restore Request', icon: <RestoreFromTrashIcon/>, onClick: () => {
                    setLoading(true);
                    services.reviewRequest.restoreRequest(request.id).then(() => {
                      setLoading(false);
                      history.push(reverse(`${routes.reviewRequests.detail}`, {id: request.id}))
                    }).catch(() => setLoading(false));
                  }
                },
              ] : [])
            ]}
            details={[
              {title: 'Type', value: request.type === 'email' ? 'Email' : 'SMS'},
              {title: 'Name', value: request.name ?? '-'},
              {title: 'Email', value: request.email ?? '-'},
              {title: 'Phone', value: request.phone ?? '-'},
              {title: 'Message', value: request.message ?? '-'},
              {
                title: 'Products',
                value: request.products.length < 1 ? '-' : request.products.map(({name}) => name).join(', '),
              },
              {
                title: 'Providers',
                value: request.providers.length < 1 ? '-' : request.providers.map(({name}) => name).join(', '),
              },
              {
                title: 'Job',
                ...(request.job?.reference ? {
                  value: request.job.reference,
                  route: reverse(routes.jobs.tab, {id: request.job.id, tab: 'review-requests'})
                } : {value: '-'})
              },
            ]}
            dialogs={[
              (props) => (
                <ConfirmationDialog
                  {...props}
                  open={openDialogs['send'] ?? false}
                  title="Send"
                  message="Are you sure you want to send this review request?"
                  onClose={confirmed => {
                    toggleDialog('send', false);
                    if (confirmed) {
                      setLoading(true);
                      services.reviewRequest.sendRequest(request.id).then((sent) => {
                        setLoading(false);
                        setRequest(sent);
                      }).catch(() => setLoading(false));
                    }
                  }}
                />
              ),
              (props) => (
                <ConfirmationDialog
                  {...props}
                  open={openDialogs['resend'] ?? false}
                  title="Resend"
                  message="Are you sure you want to resend this review request?"
                  onClose={confirmed => {
                    toggleDialog('resend', false);
                    if (confirmed) {
                      setLoading(true);
                      services.reviewRequest.sendRequest(request.id).then((sent) => {
                        setLoading(false);
                        setRequest(sent);
                      }).catch(() => setLoading(false));
                    }
                  }}
                />
              ),
              (props) => (
                <ConfirmationDialog
                  {...props}
                  open={openDialogs['delete'] ?? false}
                  title="Delete"
                  message="Are you sure you want to delete this review request?"
                  onClose={confirmed => {
                    toggleDialog('delete', false);
                    if (confirmed) {
                      setLoading(true);
                      services.reviewRequest.deleteRequest(request.id).then(() => {
                        setLoading(false);
                        history.push(routes.reviewRequests.index)
                      }).catch(() => setLoading(false));
                    }
                  }}
                />
              )
            ]}
          />,

          <DetailPane
            title="Admin Details"
            details={[
              {title: 'Status', value: <Box marginBottom={1}><Status entity={request}/></Box>},
              {title: 'Created', value: fullDateTime(request.created_at)},
              {title: 'Last Updated', value: fullDateTime(request.updated_at)},
              ...(request.sent_at ? [{
                title: 'Sent',
                value: fullDateTime(request.sent_at),
              }] : []),
              ...(request.responded_at ? [{
                title: 'Responded',
                value: fullDateTime(request.responded_at)
              }] : []),
              ...(request.archived ? [{
                title: 'Archived',
                value: request.deleted_at ? fullDateTime(request.deleted_at) : 'Relation Deleted'
              }] : [])
            ]}
          />
        ]}/>
      ) : null}
    </Wrapper>
  );
}

export default ReviewRequest;
