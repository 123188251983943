import {DataTable} from '@management-ui/core';
import {MTableToolbar} from '@material-table/core';
import {reverse} from 'named-urls';
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {ServiceContext} from '../../../../components/Services';
import routes from '../../../../routes';
import Filter from '../../components/Filter';
import Status from '../../components/Status';

const STATUSES = [
  {
    title: 'Pending',
    slug: '',
    colour: '#efcf3d'
  },
  {
    title: 'Sent',
    slug: 'sent',
    colour: '#43ded9'
  },
  {
    title: 'Responded',
    slug: 'responded',
    colour: '#30b046'
  },
  {
    title: 'All',
    slug: 'all',
    colour: '#000'
  },
];

const Table = () => {
  const services = useContext(ServiceContext);
  const history = useHistory();
  const {search, pathname} = useLocation();

  /** @type {({current: DataTable})} */
  const tableRef = useRef();
  const firstLoad = useRef(true);
  const [initialised, setInitialised] = useState(false);
  const [status, setStatus] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(search);
    const status = params.get('status');
    const current = STATUSES.find(({slug}) => slug === (status ?? ''));
    if (current) {
      setStatus(current);
      setInitialised(true);
      if (!firstLoad.current) {
        tableRef.current.refresh();
      }
      firstLoad.current = false;
    } else {
      history.push(reverse(routes.reviewRequests.index));
    }
  }, [history, search]);

  const changeStatus = useCallback((selected) => {
    history.push({
      pathname,
      search: selected ? `?status=${selected}` : ''
    });
  }, [history, pathname]);

  const goToDetail = useCallback((request) => {
    history.push(reverse(routes.reviewRequests.detail, {id: request.id}));
  }, [history]);

  const loadData = useCallback(query => new Promise((resolve, reject) => {
    const filters = {};
    if (status?.slug) {
      if (status.slug !== 'all') {
        filters.status = status.slug;
      }
    } else {
      filters.status = 'pending';
    }
    services.reviewRequest.getTableRequests(query, filters)
      .then(response => {
        resolve({
          data: response.data, page: response.meta.current_page - 1, totalCount: response.meta.total
        });
      }).catch(() => {
      reject();
    });
  }), [services, status]);

  const tableProps = useMemo(() => ({
    components: {
      Toolbar: props => (
        <div>
          <MTableToolbar {...props} />
          <Filter status={status.slug} onChange={changeStatus} statuses={STATUSES}/>
        </div>
      )
    },
    columns: [
      {title: 'Type', field: 'type', render: data => data.type === 'email' ? 'Email' : 'SMS'},
      {title: 'Name', field: 'name'},
      {title: 'Email', field: 'email'},
      {title: 'Phone', field: 'phone'},
      {title: 'Job', field: 'job', render: data => data.job?.reference ?? '-'},
      {title: 'Status', field: 'status', render: data => <Status entity={data}/>},
    ],
    onRowClick: goToDetail,
    loadData
  }), [status, changeStatus, loadData, goToDetail]);

  return initialised ? (
    <DataTable
      ref={tableRef}
      title="Review Requests"
      options={{
        search: false,
        headerStyle: {background: 'white', position: 'sticky', top: 0},
        maxBodyHeight: 'calc(100vh - 300px)',
        sorting: false,
        draggable: false
      }}
      {...tableProps}
      newForm={null}
    />
  ) : null;
};

export default Table;
