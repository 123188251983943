import * as qs from 'qs';
import {API} from './API';

export class ReviewRequestService {
  async getTableRequests(params, additional = {}) {
    const [query, page, length] = API.getTableParams(params, additional);
    return this.getRequests(query, page, length);
  }

  async getRequests(query, page, length) {
    return (await API.getConnection())
      .get('review-requests', {
        params: {...query, ...{page, length}},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data)
      .catch(error => API.handleError(error));
  }

  async getRequest(id, archive = false) {
    return (await API.getConnection())
      .get(`review-requests/${id}`, {
        params: archive ? {filter: {archive: 'only'}} : {},
        paramsSerializer: params => qs.stringify(params),
      })
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async saveRequest(data) {
    let method = 'post';
    let url = 'review-requests';
    if (data.id) {
      method = 'put';
      url = `${url}/${data.id}`;
    }
    return (await API.getConnection())
      [method](url, data)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async sendRequest(id) {
    return (await API.getConnection())
      .post(`review-requests/${id}/send`)
      .then(response => response.data.data)
      .catch(error => API.handleError(error));
  }

  async deleteRequest(id) {
    return (await API.getConnection())
      .delete(`review-requests/${id}`)
      .then(response => response)
      .catch(error => API.handleError(error));
  }

  async restoreRequest(id) {
    return (await API.getConnection())
      .put(`review-requests/${id}/restore`)
      .then(response => response)
      .catch(error => API.handleError(error));
  }
}
