import {BubblesField, Panes, prefixWithSeparator, Select, TextField} from '@management-ui/core';
import React, {useCallback} from 'react';
import validator from 'email-validator';
import {useOptions} from '../../../../hooks';
import {useTheme} from '@mui/material';
import {useFormContext} from 'react-hook-form';

const Fields = ({request = {}, prefix = '', children}) => {
  const theme = useTheme();

  const {/** @var {function(string): string} */watch} = useFormContext();

  const type = watch(`${prefixWithSeparator(prefix)}type`);

  const products = useOptions('reviewProducts', useCallback((list) => list.map(product => ({
    id: product.id,
    label: product.name,
    colour: theme.palette.primary.main,
  })), [theme]));

  const providers = useOptions('reviewProviders', useCallback((list) => list.map(provider => ({
    id: provider.id,
    label: provider.name,
    colour: theme.palette.secondary.main,
  })), [theme]));

  return (
    <Panes
      entity={request}
      prefix={prefix}
      panes={[
        {
          title: 'Contact Details',
          fields: [
            <Select
              name="type"
              prefix={prefix}
              options={[
                {title: 'Email', value: 'email'},
                {title: 'SMS', value: 'sms'}
              ]}
              label="Type"
            />,
            <TextField
              name="name"
              prefix={prefix}
              label="Name"
              rules={{required: 'Please enter a name'}}
            />,
            <TextField
              name="email"
              prefix={prefix}
              label="Email Address"
              rules={{
                ...(type === 'email' ? {
                  required: 'Please enter an email address'
                } : {}),
                validate: value => {
                  if (value) {
                    return validator.validate(value) ? true : 'Please enter a valid email address';
                  }
                  return true;
                }
              }}
              autoComplete="email"
            />,
            <TextField
              name="phone"
              prefix={prefix}
              label="Telephone Number"
              rules={type === 'sms' ? {
                required: 'Please enter a mobile phone number'
              } : {}}
            />,
            <TextField
              name="message"
              prefix={prefix}
              label="Message"
              fieldProps={{
                multiline: true,
                rows: 5
              }}
            />
          ]
        },
        {
          title: 'Reviews to Request',
          fields: [
            <BubblesField
              name="products"
              prefix={prefix}
              label="Products"
              bubbles={products}
              multiSelect={type === 'email'}
            />,
            <BubblesField
              name="providers"
              prefix={prefix}
              label="Providers"
              bubbles={providers}
              multiSelect={type === 'email'}
            />
          ]
        }
      ]}
      title={null}
    >{children}</Panes>
  );
};

export default Fields;
