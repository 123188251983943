const routes = {
  // Access
  login: '/auth/login',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password',

  // Admin
  dashboard: '/',
  calendar: '/calendar',
  bookings: {
    index: '/bookings',
    notes: '/bookings/quick-allocations'
  },
  jobs: {
    index: '/jobs',
    new: '/jobs/new',
    detail: '/jobs/:id',
    tab: '/jobs/:id/:tab',
    allocations: {
      new: '/jobs/:id/allocations/new',
      edit: '/jobs/:jobID/allocations/:id/edit',
      detail: '/jobs/:jobID/allocations/:id'
    }
  },
  invoices: {
    index: '/invoices',
    detail: '/invoices/:id',
    tab: '/invoices/:id/:tab'
  },
  subscriptions: {
    index: '/subscriptions',
    detail: '/subscriptions/:id',
    tab: '/subscriptions/:id/:tab'
  },
  orders: {
    index: '/orders',
    detail: '/orders/:id',
    tab: '/orders/:id/:tab',
    deliveries: {
      detail: '/orders/:orderID/deliveries/:id'
    }
  },
  dateRequests: {
    index: '/orders/date-requests',
    detail: '/orders/date-requests/:id',
    tab: '/orders/date-requests/:id/:tab'
  },
  reviewRequests: {
    index: '/orders/review-requests',
    detail: '/orders/review-requests/:id',
    tab: '/orders/review-requests/:id/:tab'
  },
  referrers: {
    index: '/orders/referrers',
    detail: '/orders/referrers/:id'
  },
  companies: {
    index: '/companies',
    detail: '/companies/:id',
    tab: '/companies/:id/:tab'
  },
  contacts: {
    index: '/contacts',
    detail: '/contacts/:id',
    tab: '/contacts/:id/:tab',
  },
  vehicles: {
    index: '/vehicles',
    detail: '/vehicles/:id',
    tab: '/vehicles/:id/:tab',
  },
  engineers: {
    index: '/engineers',
    detail: '/engineers/:id',
    tab: '/engineers/:id/:tab',
    activity: '/engineers/:id/activity',
  },
  stock: {
    index: '/stock/:tab?',
    products: {
      detail: '/stock/products/:id',
      tab: '/stock/products/:id/:tab'
    },
    locations: {
      detail: '/stock/locations/:id'
    },
    items: {
      detail: '/stock/items/:id',
      activity: '/stock/items/:id/activity'
    },
    virtualProducts: {
      detail: '/stock/virtual-products/:id'
    },
  },
  emails: '/emails',
  reporting: {
    index: '/reporting/:tab?',
  },
  administrators: '/administrators',
  management: '/management/:tab?',
  developer: {
    index: '/developer/:tab?',
    gatewayAccounts: {
      verify: '/developer/gateway-accounts/verify',
      detail: '/developer/gateway-accounts/:id'
    },
  },
  archive: {
    index: '/archive/:tab?',
    jobs: {
      detail: '/archive/jobs/:id',
      tab: '/archive/jobs/:id/:tab',
      allocations: {
        detail: '/archive/jobs/:jobID/allocations/:id'
      }
    },
    companies: {
      detail: '/archive/companies/:id',
      tab: '/archive/companies/:id/:tab'
    },
    contacts: {
      detail: '/archive/contacts/:id',
      tab: '/archive/contacts/:id/:tab'
    },
    vehicles: {
      detail: '/archive/vehicles/:id',
      tab: '/archive/vehicles/:id/:tab',
    },
    engineers: {
      detail: '/archive/engineers/:id',
      activity: '/archive/engineers/:id/activity'
    },
    products: {
      detail: '/archive/products/:id',
      tab: '/archive/products/:id/:tab'
    },
    locations: {
      detail: '/archive/locations/:id'
    },
    items: {
      detail: '/archive/items/:id',
      activity: '/archive/items/:id/activity'
    },
    virtualProducts: {
      detail: '/archive/virtual-products/:id'
    },
    referrers: {
      index: '/orders/referrers',
      detail: '/orders/referrers/:id'
    }
  }
};

export default routes;
