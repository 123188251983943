import React, {createContext} from 'react';
import {AccessKeyService} from '../services/AccessKeyService';
import {AuthService} from '../services/AuthService';
import {BatteryTypeService} from '../services/BatteryTypeService';
import {CalendarNoteService} from '../services/CalendarNoteService';
import {CompanyService} from '../services/CompanyService';
import {ContactService} from '../services/ContactService';
import {CostService} from '../services/CostService';
import {CountryService} from '../services/CountryService';
import {DateRequestService} from '../services/DateRequestService';
import {DeliveryService} from '../services/DeliveryService';
import {FuelTypeService} from '../services/FuelTypeService';
import {GatewayAccountService} from '../services/GatewayAccountService';
import {InvoiceService} from '../services/InvoiceService';
import {JobService} from '../services/JobService';
import {JobTemplateService} from '../services/JobTemplateService';
import {LocationNameService} from '../services/LocationNameService';
import {LocationTypeService} from '../services/LocationTypeService';
import {OrderService} from '../services/OrderService';
import {OutboundMessageService} from '../services/OutboundMessageService';
import {PostcodeService} from '../services/PostcodeService';
import {ProductService} from '../services/ProductService';
import {ReferrerService} from '../services/ReferrerService';
import {ReportingService} from '../services/ReportingService';
import {ScorpionLogService} from '../services/ScorpionLogService';
import {ServiceProvider} from '../services/ServiceProvider';
import {SettingsService} from '../services/SettingsService';
import {StockItemService} from '../services/StockItemService';
import {StockLocationService} from '../services/StockLocationService';
import {SubscriptionService} from '../services/SubscriptionService';
import {UserService} from '../services/UserService';
import {VehicleService} from '../services/VehicleService';
import {VehicleTypeService} from '../services/VehicleTypeService';
import {VirtualProductService} from '../services/VirtualProductService';
import {ReviewProviderService} from '../services/ReviewProviderService';
import {EngineerAvailabilityNoteService} from '../services/EngineerAvailabilityNoteService';
import {ReviewRequestService} from '../services/ReviewRequestService';

export const ServiceContext = createContext({})

export default function Services({children}) {
  const services = {
    accessKey: new AccessKeyService(),
    auth: new AuthService(),
    batteryType: new BatteryTypeService(),
    calendarNote: new CalendarNoteService(),
    company: new CompanyService(),
    contact: new ContactService(),
    cost: new CostService(),
    country: new CountryService(),
    dateRequest: new DateRequestService(),
    delivery: new DeliveryService(),
    engineerAvailabilityNote: new EngineerAvailabilityNoteService(),
    fuelType: new FuelTypeService(),
    gatewayAccount: new GatewayAccountService(),
    invoice: new InvoiceService(),
    job: new JobService(),
    jobTemplate: new JobTemplateService(),
    locationName: new LocationNameService(),
    locationType: new LocationTypeService(),
    order: new OrderService(),
    outboundMessage: new OutboundMessageService(),
    postcode: new PostcodeService(),
    product: new ProductService(),
    referrer: new ReferrerService(),
    reporting: new ReportingService(),
    reviewProvider: new ReviewProviderService(),
    reviewRequest: new ReviewRequestService(),
    scorpionLog: new ScorpionLogService(),
    settings: new SettingsService(),
    stockItem: new StockItemService(),
    stockLocation: new StockLocationService(),
    subscription: new SubscriptionService(),
    user: new UserService(),
    vehicle: new VehicleService(),
    vehicleType: new VehicleTypeService(),
    virtualProduct: new VirtualProductService()
  };
  ServiceProvider.setServices(services);
  return (
    <ServiceContext.Provider value={services}>{children}</ServiceContext.Provider>
  );
}
