import {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {name} from '@management-ui/core';
import {
  loadBatteryTypes,
  loadCountries,
  loadFuelTypes,
  loadGatewayAccounts,
  loadInstallers,
  loadJobTemplates,
  loadLocationNames,
  loadLocationTypes,
  loadManufacturers,
  loadProducts,
  loadReviewProducts,
  loadReviewProviders,
  loadStockLocations,
  loadSuppliers,
  loadUsers,
  loadVehicleTypes,
  loadVirtualProducts
} from './store/actions/options';

const OPTION_LOADERS = {
  batteryTypes: loadBatteryTypes,
  countries: loadCountries,
  fuelTypes: loadFuelTypes,
  gatewayAccounts: loadGatewayAccounts,
  installers: loadInstallers,
  jobTemplates: loadJobTemplates,
  locationNames: loadLocationNames,
  locationTypes: loadLocationTypes,
  manufacturers: loadManufacturers,
  products: loadProducts,
  reviewProducts: loadReviewProducts,
  reviewProviders: loadReviewProviders,
  stockLocations: loadStockLocations,
  suppliers: loadSuppliers,
  users: loadUsers,
  vehicleTypes: loadVehicleTypes,
  virtualProducts: loadVirtualProducts,
};

let LOADING = [];

export function useOptions(type, formatter) {
  const list = useSelector(state => state['options'][type]);
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (list.length < 1) {
      if (LOADING.indexOf(type) < 0) {
        LOADING.push(type);
        dispatch(OPTION_LOADERS[type]());
      }
    } else {
      const index = LOADING.indexOf(type);
      if (index >= 0) {
        LOADING.splice(index, 1);
        LOADING = [...LOADING];
      }
    }
  }, [list, type, dispatch]);

  useEffect(() => {
    setOptions(formatter ? formatter(list) : list);
  }, [list, formatter]);

  return options;
}

export function useCountries() {
  return useOptions('countries', useCallback((list) => list.map(country => ({
    value: country.id,
    title: `${country.name} (${country.code})`
  })), []));
}

export function useLoading() {
  const [loading, setLoading] = useState(false);
  const loadingCount = useRef(0);

  const handleLoading = useCallback((loading) => {
    if (loading) {
      loadingCount.current += 1;
    } else {
      loadingCount.current -= 1;
    }
    setLoading(loadingCount.current > 0);
  }, []);

  return {loading, setLoading: handleLoading};
}

export function useEngineers() {
  return useOptions('users', useCallback((list) => {
    const regular = [];
    const other = [];
    list.filter(u => u.is_engineer).forEach((engineer) => {
      const data = {
        value: engineer.id,
        title: name(engineer)
      };
      if (engineer.is_regular) {
        regular.push(data);
      } else {
        other.push(data);
      }
    });
    return [
      {title: 'Regular Engineers'},
      ...regular,
      {title: 'Other Engineers'},
      ...other
    ];
  }, []));
}
