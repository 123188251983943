import {Authenticated} from '@management-ui/core';
import {DriveEta, ShoppingBasket} from '@mui/icons-material';
import ArchiveIcon from '@mui/icons-material/Archive';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarToday from '@mui/icons-material/CalendarToday';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import EmailIcon from '@mui/icons-material/Email';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import GridOnIcon from '@mui/icons-material/GridOn';
import PeopleIcon from '@mui/icons-material/People';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import SecurityIcon from '@mui/icons-material/Security';
import StorageIcon from '@mui/icons-material/Storage';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import {makeStyles} from '@mui/styles';
import * as React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router-dom';
import logo from '../../assets/safe-and-sound.svg';
import routes from '../../routes';
import {logout} from '../../store/actions/auth';
import EngineersByPostcode from './components/EngineersByPostcode';
import ScorpionLogs from './components/ScorpionLogs';
import Administrators from './screens/Administrators';
import Archive from './screens/Archive';
import Bookings from './screens/Bookings';
import Calendar from './screens/Calendar';
import CalendarNotes from './screens/CalendarNotes';
import Companies from './screens/Companies';
import Company from './screens/Company';
import Contact from './screens/Contact';
import Contacts from './screens/Contacts';
import DateRequest from './screens/DateRequest';
import DateRequests from './screens/DateRequests';
import Developer from './screens/Developer';
import Engineer from './screens/Engineer';
import EngineerActivity from './screens/Engineer/Activity';
import Engineers from './screens/Engineers';
import GatewayAccount from './screens/GatewayAccount';
import Verify from './screens/GatewayAccount/Verify';
import Invoice from './screens/Invoice';
import Invoices from './screens/Invoices';
import Job from './screens/Job';
import JobAllocation from './screens/Job/Allocation';
import EditJobAllocation from './screens/Job/Allocation/Edit';
import NewJobAllocation from './screens/Job/Allocation/New';
import Jobs from './screens/Jobs';
import Management from './screens/Management';
import NewJob from './screens/NewJob';
import Order from './screens/Order';
import Delivery from './screens/Order/Delivery';
import Orders from './screens/Orders';
import OutboundMessages from './screens/OutboundMessages';
import Product from './screens/Product';
import Referrer from './screens/Referrer';
import Referrers from './screens/Referrers';
import Reporting from './screens/Reporting';
import Stock from './screens/Stock';
import StockItem from './screens/StockItem';
import StockItemActivity from './screens/StockItem/Activity';
import StockLocation from './screens/StockLocation';
import Subscription from './screens/Subscription';
import Subscriptions from './screens/Subscriptions';
import Vehicle from './screens/Vehicle';
import Vehicles from './screens/Vehicles';
import VirtualProduct from './screens/VirtualProduct';
import ReviewRequests from './screens/ReviewRequests';
import ReviewRequest from './screens/ReviewRequest';

const useStyles = makeStyles(theme => ({
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-end',
  },

  link: {
    transition: '0.25s opacity ease-in-out',

    '& span': {
      fontSize: '1.1em',
    },

    '&:hover': {
      color: theme.palette.secondary.main,
    }
  },
}));

const Admin = (props) => {
  const classes = useStyles();
  const user = useSelector(state => state['auth'].user);
  const dispatch = useDispatch();
  return (
    <Authenticated
      title="Safe & Sound"
      logo={logo}
      logoWidth="60%"
      userName={user ? user.first_name : ''}
      nav={[
        {title: 'Calendar', route: routes.dashboard, icon: <CalendarToday/>},
        {title: 'Bookings', route: routes.bookings.index, icon: <DynamicFeedIcon/>},
        {title: 'Jobs', route: routes.jobs.index, icon: <WorkOutlineIcon/>},
        {title: 'Invoices', route: routes.invoices.index, icon: <AttachMoneyIcon/>},
        {title: 'Subscriptions', route: routes.subscriptions.index, icon: <SubscriptionsIcon/>},
        {title: 'Online Orders', route: routes.orders.index, icon: <ShoppingBasket/>},
        {title: 'Companies', route: routes.companies.index, icon: <RecentActorsIcon/>},
        {title: 'Contacts', route: routes.contacts.index, icon: <PeopleIcon/>},
        {title: 'Vehicles', route: routes.vehicles.index, icon: <DriveEta/>},
        {title: 'Engineers', route: routes.engineers.index, icon: <EmojiTransportationIcon/>},
        {title: 'Stock', route: routes.stock.index, icon: <GridOnIcon/>},
        {title: 'Emails', route: routes.emails, icon: <EmailIcon/>},
        {title: 'Reporting', route: routes.reporting.index, icon: <GraphicEqIcon/>},
        {title: 'Administrators', route: routes.administrators, icon: <SecurityIcon/>},
        {title: 'Management', route: routes.management, icon: <StorageIcon/>},
        {title: 'Developer', route: routes.developer.index, icon: <DeveloperBoardIcon/>},
        {title: 'Archive', route: routes.archive.index, icon: <ArchiveIcon/>},
      ]}
      onLogout={() => dispatch(logout())}
      sidebarContent={
        <div className={classes.container}>
          <ScorpionLogs linkClasses={classes.link}/>
          <EngineersByPostcode linkClasses={classes.link}/>
        </div>
      }
    >
      <Switch>
        <Route
          exact
          path={routes.dashboard}
          render={routeProps => <Calendar {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.calendar}
          render={() => <Redirect to={`${routes.dashboard}`}/>}/>

        <Route
          exact
          path={routes.bookings.index}
          render={routeProps => <Bookings {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.bookings.notes}
          render={routeProps => <CalendarNotes {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.jobs.index}
          render={routeProps => <Jobs {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.jobs.new}
          render={routeProps => <NewJob {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.jobs.detail}
          render={routeProps => <Job {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.jobs.allocations.new}
          render={routeProps => <NewJobAllocation {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.jobs.allocations.detail}
          render={routeProps => <JobAllocation {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.jobs.allocations.edit}
          render={routeProps => <EditJobAllocation {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.jobs.tab}
          render={routeProps => <Job {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.invoices.index}
          render={routeProps => <Invoices {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.invoices.detail}
          render={routeProps => <Invoice {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.invoices.tab}
          render={routeProps => <Invoice {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.subscriptions.index}
          render={routeProps => <Subscriptions {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.subscriptions.detail}
          render={routeProps => <Subscription {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.subscriptions.tab}
          render={routeProps => <Subscription {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.dateRequests.index}
          render={routeProps => <DateRequests {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.dateRequests.detail}
          render={routeProps => <DateRequest {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.dateRequests.tab}
          render={routeProps => <DateRequest {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.reviewRequests.index}
          render={routeProps => <ReviewRequests {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.reviewRequests.detail}
          render={routeProps => <ReviewRequest {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.reviewRequests.tab}
          render={routeProps => <ReviewRequest {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.referrers.index}
          render={routeProps => <Referrers {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.referrers.detail}
          render={routeProps => <Referrer {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.orders.index}
          render={routeProps => <Orders {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.orders.detail}
          render={routeProps => <Order {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.orders.tab}
          render={routeProps => <Order {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.orders.deliveries.detail}
          render={routeProps => <Delivery {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.contacts.index}
          render={routeProps => <Contacts {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.contacts.detail}
          render={routeProps => <Contact {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.contacts.tab}
          render={routeProps => <Contact {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.companies.index}
          render={routeProps => <Companies {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.companies.detail}
          render={routeProps => <Company {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.companies.tab}
          render={routeProps => <Company {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.vehicles.index}
          render={routeProps => <Vehicles {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.vehicles.detail}
          render={routeProps => <Vehicle {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.vehicles.tab}
          render={routeProps => <Vehicle {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.engineers.index}
          render={routeProps => <Engineers {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.engineers.detail}
          render={routeProps => <Engineer {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.engineers.tab}
          render={routeProps => <Engineer {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.engineers.activity}
          render={routeProps => <EngineerActivity {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.stock.index}
          render={routeProps => <Stock {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.stock.products.detail}
          render={routeProps => <Product {...props} {...routeProps}/>}/>
        <Route
          exact
          path={routes.stock.products.tab}
          render={routeProps => <Product {...props} {...routeProps}/>}/>

        <Route
          exact
          path={routes.stock.locations.detail}
          render={routeProps => <StockLocation {...props} {...routeProps}/>}/>

        <Route
          exact
          path={routes.stock.items.detail}
          render={routeProps => <StockItem {...props} {...routeProps}/>}/>
        <Route
          exact
          path={routes.stock.items.activity}
          render={routeProps => <StockItemActivity {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.stock.virtualProducts.detail}
          render={routeProps => <VirtualProduct {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.emails}
          render={routeProps => <OutboundMessages {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.reporting.index}
          render={routeProps => <Reporting {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.administrators}
          render={routeProps => <Administrators {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.management}
          render={routeProps => <Management {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.developer.index}
          render={routeProps => <Developer {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.developer.gatewayAccounts.verify}
          render={routeProps => <Verify {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.developer.gatewayAccounts.detail}
          render={routeProps => <GatewayAccount {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.archive.index}
          render={routeProps => <Archive {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.archive.jobs.detail}
          render={routeProps => <Job archive={true} {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.archive.jobs.allocations.detail}
          render={routeProps => <JobAllocation archive={true} {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.archive.jobs.tab}
          render={routeProps => <Job archive={true} {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.archive.companies.detail}
          render={routeProps => <Company archive={true} {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.archive.companies.tab}
          render={routeProps => <Company archive={true} {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.archive.contacts.detail}
          render={routeProps => <Contact archive={true} {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.archive.contacts.tab}
          render={routeProps => <Contact archive={true} {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.archive.vehicles.detail}
          render={routeProps => <Vehicle archive={true} {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.archive.vehicles.tab}
          render={routeProps => <Vehicle archive={true} {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.archive.engineers.detail}
          render={routeProps => <Engineer archive={true} {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.archive.engineers.activity}
          render={routeProps => <EngineerActivity archive={true} {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.archive.products.detail}
          render={routeProps => <Product archive={true} {...props} {...routeProps}/>}/>

        <Route
          exact
          path={routes.archive.locations.detail}
          render={routeProps => <StockLocation archive={true} {...props} {...routeProps}/>}/>

        <Route
          exact
          path={routes.archive.items.detail}
          render={routeProps => <StockItem archive={true} {...props} {...routeProps}/>}/>
        <Route
          exact
          path={routes.archive.items.activity}
          render={routeProps => <StockItemActivity archive={true} {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.archive.virtualProducts.detail}
          render={routeProps => <VirtualProduct archive={true} {...props} {...routeProps} />}/>

        <Route
          exact
          path={routes.archive.referrers.index}
          render={routeProps => <Referrers archive={true} {...props} {...routeProps} />}/>
        <Route
          exact
          path={routes.archive.referrers.detail}
          render={routeProps => <Referrer archive={true} {...props} {...routeProps} />}/>
      </Switch>
    </Authenticated>
  );
};

export default Admin;
