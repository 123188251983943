import {BaseForm} from '@management-ui/core';
import * as React from 'react';
import {forwardRef, useCallback, useContext, useImperativeHandle, useMemo, useRef} from 'react';
import {ServiceContext} from '../../../../components/Services';
import Fields from './Fields';

const ReviewRequestForm = forwardRef(({request, job, onSaved}, ref) => {
  const services = useContext(ServiceContext);
  /** @type {({current: BaseForm})} */
  const formRef = useRef(null);

  useImperativeHandle(ref, () => ({
    save() {
      formRef.current.save();
    }
  }));

  const initial = useMemo(() => {
    if (request.id) {
      return {...request};
    } else if (job?.contact) {
      return {
        type: 'email',
        name: job.contact.first_name,
        email: job.contact.email,
        phone: job.contact.phone,
      }
    }
  }, [request, job])

  const handleSave = useCallback(async ({type, name, email, phone, message, products, providers}) => {
    return services.reviewRequest.saveRequest({
      ...(request?.id ? {id: request.id} : {type}),
      name,
      email,
      phone,
      message,
      ...(type === 'email' ? {products, providers} : {
        ...(products ? {products: [products]} : {}),
        ...(providers ? {providers: [providers]} : {})
      }),
      ...(job ? {job_id: job.id,} : {}),
      ...(job?.contact ? {contact_id: job.contact.id,} : {}),
    })
  }, [request, job, services]);

  return (
    <BaseForm
      ref={formRef}
      entity={initial}
      type="request"
      fieldsComponent={(props) => <Fields {...props}/>}
      onSave={handleSave}
      onSaved={onSaved}
    />
  );
});

export default ReviewRequestForm;
